import {useSelector} from "react-redux";
import {selectors} from "../../redux/ReportsRedux";
import {TAB_NAME, IReportDetails} from '../../models/Reports'
import React from "react";
import clsx from "clsx";
import CommentTab from "../SeoStatistic/Tabs/CommentTab";
import AllegroDataStatisticTab from "./Tabs/AllegroDataStatisticTab";

interface IAllegroTabsContent {
    tab: string
}
const AllegroTabsContent: React.FC<IAllegroTabsContent> = ({ tab }) => {
    const reportData = useSelector(selectors.getReportData) as IReportDetails

  return (
    <>
      <div className='tab-content border'>
        <div className={clsx('tab-pane', {active: tab === TAB_NAME.COMMENT})}>
          <CommentTab />
        </div>

        {reportData && reportData.details && reportData.details.allegroDaily && (
          <div className={clsx('tab-pane', {active: tab === TAB_NAME.ANALYSIS})}>
            <AllegroDataStatisticTab />
          </div>
        )}
      </div>
    </>
  )
};

export default AllegroTabsContent;
