import axios, {AxiosResponse} from 'axios'
import {IServiceRating} from '../models/Reports'

const API_URL = process.env.REACT_APP_API_URL || 'api'

export const REPORT_API = `${API_URL}/reports`

export async function getReportsList(
  companyId: number,
  page: number,
  perPage: number,
  filter: string,
  search?: string
): Promise<AxiosResponse<unknown>> {
  if (search)
    return await axios.get(
      `${REPORT_API}?company_id=${companyId}&search=${search}&page=${page}&perPage=${perPage}`
    )
  return await axios.get(
    `${REPORT_API}?company_id=${companyId}&page=${page}&perPage=${perPage}&${filter}`
  )
}

export async function getRaportById(
  id: number,
  companyId: number
): Promise<AxiosResponse<unknown>> {
  return await axios.get(`${REPORT_API}/${id}?company_id=${companyId}`)
  // return await axios.get(`${REPORT_API}/${id}}`)
}

export async function getServiceRating(
  id: number,
  clientId: number
): Promise<IServiceRating | undefined> {
  try {
    const response = await axios.get(`${REPORT_API}/servicesRating/${id}/${clientId}`);
    return response.status === 200 ? response.data.servicesRating :  undefined;
  } catch (e) {
    return undefined;
  }
}

export async function getReportByHash(
  reportId: number,
  clientId: number,
  email: string,
  accessHash: string,
): Promise<AxiosResponse<unknown>> {
  return await axios.get(
    `${REPORT_API}/getByHash/${reportId}/${clientId}/${email}/${accessHash}`
  );
}
export async function getReportsPdf(id: number, companyId: number) {
  return await axios.get(`${REPORT_API}/${id}/pdf?company_id=${companyId}`, {responseType: 'blob'})
}

export async function brandListSEM(
  id: number,
  companyId: number,
  page: number,
  perPage: number,
  filters: string,
  search?: string,
  hash?: string,
  email?: string
) {
  const params: any = {
    company_id: companyId,
    page: page,
    perPage: perPage,
    filters: filters,
  };

  if (search) {
    params.search = search;
  }

  if (hash || email) {
    if (hash) params.hash = hash;
    if (email) params.email = email;
  }

  return await axios.get(`${REPORT_API}/${id}/sem/products/brand`, { params });
}

export async function categoryListSEM(
  id: number,
  companyId: number,
  page: number,
  perPage: number,
  filters: string,
  search?: string,
  hash?: string,
  email?: string
) {
  const params: any = {
    company_id: companyId,
    page: page,
    perPage: perPage,
    filters: filters,
  };

  if (search) {
    params.search = search;
  }

  if (hash || email) {
    if (hash) params.hash = hash;
    if (email) params.email = email;
  }

  return await axios.get(`${REPORT_API}/${id}/sem/products/category`, { params });
}

export async function efficiencyListSEM(
  id: number,
  companyId: number,
  page: number,
  perPage: number,
  filters: string,
  search?: string,
  hash?: string,
  email?: string
) {
  const params: any = {
    company_id: companyId,
    page: page,
    perPage: perPage,
    filters: filters,
  };

  if (search) {
    params.search = search;
  }

  if (hash || email) {
    if (hash) params.hash = hash;
    if (email) params.email = email;
  }

  return await axios.get(`${REPORT_API}/${id}/sem/products/efficiency`, { params });
}

export async function efficiencyListSEO(
  id: number,
  companyId: number,
  page: number,
  hash?: string,
  email?: string
) {
  const params: any = {
    company_id: companyId,
    page: page,
  };

  if (hash || email) {
    if (hash) params.hash = hash;
    if (email) params.email = email;
  }

  return await axios.get(`${REPORT_API}/${id}/1/products/efficiency/10`, { params });
}

export async function categoryListSEO(
  id: number,
  companyId: number,
  page: number,
  hash?: string,
  email?: string
) {
  const params: any = {
    company_id: companyId,
    page: page,
  };

  if (hash || email) {
    if (hash) params.hash = hash;
    if (email) params.email = email;
  }

  return await axios.get(`${REPORT_API}/${id}/1/products/category/10`, { params });
}

export async function brandListSEO(
  id: number,
  companyId: number,
  page: number,
  hash?: string,
  email?: string
) {
  const params: any = {
    company_id: companyId,
    page: page,
  };

  if (hash || email) {
    if (hash) params.hash = hash;
    if (email) params.email = email;
  }

  return await axios.get(`${REPORT_API}/${id}/1/products/brand/10`, { params });
}

export async function topPhrasesList(
  id: number,
  companyId: number,
  page: number,
  hash?: string,
  email?: string
) {
  const params: any = {
    company_id: companyId,
    page: page,
  };

  if (hash || email) {
    if (hash) params.hash = hash;
    if (email) params.email = email;
  }

  return await axios.get(`${REPORT_API}/${id}/searchConsoleDetail/query/10`, { params });
}


export async function topPagesList(id: number, companyId: number, page: number, hash?: string, email?: string) {
  return await axios.get(
    `${REPORT_API}/${id}/searchConsoleDetail/pages/10`, {
      params: {
        company_id: companyId,
        page: page,
        hash: hash || null,
        email: email || null
      }
    }
  );
}

export async function topPhrasesCompareList(id: number, companyId: number, page: number, hash?: string, email?: string) {
  return await axios.get(
    `${REPORT_API}/${id}/searchConsoleCompare/10`, {
      params: {
        company_id: companyId,
        page: page,
        hash: hash || null,
        email: email || null
      }
    }
  );
}
