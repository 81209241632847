import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {actions} from '../../../redux/SeoReportListsRedux'
import {selectors as authSelectors} from '../../../../auth'
import {selectors as reportSelectors} from '../../../redux/ReportsRedux'
import {useIntl} from 'react-intl'
import {selectors as seoReportsListSelectors} from '../../../redux/SeoReportListsRedux'
import ErrorView from '../../../../common/ErrorView/ErrorView'
import Spinner from '../../../../common/Spinner/Spinner'
import GSCTimeCompareList from '../SeoWidgets/GSCTimeCompareList'
import {IReportDetails} from '../../../models/Reports'
import {useParams} from 'react-router-dom'

interface GSCTopPhrasesCompareListsProps {
  print?: boolean
}

const GSCTopPhrasesCompareLists: React.FC<GSCTopPhrasesCompareListsProps> = (props) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const {reportsLoading, reportsError, reportId} = useSelector(reportSelectors.getReportsState)
  const { companyId: companyIdParam, email, accessHash } = useParams<{ companyId?: string; email?: string; accessHash?: string }>();

  const companyId = companyIdParam ? parseInt(companyIdParam, 10) : 0;
  const companyIdAuth = useSelector(authSelectors.getCompanyId);
  const usedClientId = companyIdAuth ? companyIdAuth : companyId;

  const {
    topPhrasesCompareError,
    topPhrasesCompareLoading,
    topPhrasesCompareList,
    topPhrasesCompareTotalPages,
  } = useSelector(seoReportsListSelectors.getReportListsState)
  const {date} = useSelector(reportSelectors.getReportData) as IReportDetails
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [currDate, setCurrDate] = useState<Date>()
  const [prevYear, setPrevYear] = useState<Date>()
  const [prevMonth, setPrevMonth] = useState<Date>()
  const [shouldDisplay, setShouldDisplay] = useState<boolean>(false)

  useEffect(() => {
    if (date) {
      setCurrDate(new Date(date))
      const curYear = new Date(date)
      const yearAgo = curYear.setFullYear(curYear.getFullYear() - 1)
      setPrevYear(new Date(yearAgo))

      const curMonth = new Date(date)
      const monthAgo = curMonth.setMonth(curMonth.getMonth() - 1)
      setPrevMonth(new Date(monthAgo))
    }
  }, [date])

  useEffect(() => {
    if (reportId && companyId)
      dispatch(actions.requestTopPhrasesCompareList(reportId, usedClientId, currentPage, email, accessHash))
  }, [dispatch, reportId, usedClientId, currentPage])

  useEffect(() => {
    if (topPhrasesCompareList && topPhrasesCompareList.length > 0) {
      setShouldDisplay(true)
    }

  }, [topPhrasesCompareList]);

  return !reportsError && !topPhrasesCompareError ? (
    <div
      className={reportsLoading ? 'overlay overlay-block rounded' : 'd-flex justify-content-center'}
    >
      {!reportsLoading && !topPhrasesCompareLoading ? (
        shouldDisplay && <div className='row'>
          <div className='col-6'>
            <div className='row'>
              <div className='px-5 py-5 pb-0'>
                <h3 className='fs-3 fw-boldest text-sempai-green'>
                  {'TOP frazy - w ujęciu '}
                  {`${intl.formatDate(currDate, {
                    month: 'short',
                    year: 'numeric',
                  })} - ${intl.formatDate(prevMonth, {
                    month: 'short',
                    year: 'numeric',
                  })}`}
                </h3>
              </div>
              <div className='bg-white px-0 py-0 pb-0'>
                {topPhrasesCompareList && (
                  <GSCTimeCompareList
                    list={topPhrasesCompareList}
                    time='month'
                    loading={topPhrasesCompareLoading}
                  />
                )}
              </div>
            </div>
          </div>
          <div className='col-6'>
            <div className='row'>
              <div className='px-5 py-5 pb-0'>
                <h3 className='fs-3 fw-boldest text-sempai-green'>
                  {'TOP frazy - w ujęciu '}
                  {`${intl.formatDate(currDate, {
                    month: 'short',
                    year: 'numeric',
                  })} - ${intl.formatDate(prevYear, {
                    month: 'short',
                    year: 'numeric',
                  })}`}
                </h3>
              </div>
              <div className='bg-white px-0 py-0 pb-0'>
                {topPhrasesCompareList && (
                  <GSCTimeCompareList
                    list={topPhrasesCompareList}
                    loading={topPhrasesCompareLoading}
                    time='year'
                    pagination={props.print ? false : true}
                    pages={topPhrasesCompareTotalPages}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    paginationPosition={'end'}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className='d-flex align-items-center' style={{height: '450px'}}>
          <Spinner color='white' />
        </div>
      )}
    </div>
  ) : (
    <ErrorView />
  )
}

export default GSCTopPhrasesCompareLists
