import { Suspense } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { I18nProvider } from '../_metronic/i18n/i18nProvider'
import { LayoutProvider, LayoutSplashScreen } from '../_metronic/layout/core'
import AuthInit from './modules/auth/redux/AuthInit'
import { Routes } from './routing/Routes'
import ReportView from './modules/reports/ReportView'

type Props = {
  basename: string
}

const App: React.FC<Props> = ({ basename }) => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <BrowserRouter basename={basename}>
        <I18nProvider>
          <Switch>
              <Route path='/report-access/:companyId/:email/:reportId/:accessHash'>
                  <div className={'w-75 mx-auto'}>
                    <ReportView />
                  </div>
              </Route>
            <Route path='/reports/:companyId/:reportId/isolated'>
                <ReportView notIsolated={false}/>
            </Route>
            <Route>
              <LayoutProvider>
                <AuthInit>
                  <Routes />
                </AuthInit>
              </LayoutProvider>
            </Route>
          </Switch>
        </I18nProvider>
      </BrowserRouter>
    </Suspense>
  )
}

export { App }
