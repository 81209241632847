import {AxiosResponse} from 'axios'
import {put, select, takeLatest} from 'redux-saga/effects'
import {ActionWithPayload, RootState} from '../../../../setup'
import {selectors as authSelectors} from '../../auth/redux/AuthRedux'
import {IReportDetails, IReports, IServiceRating} from '../models/Reports'
import {getRaportById, getReportByHash, getReportsList, getServiceRating} from './ReportsCRUD'

export const REPORTS_REQUEST = 'REPORTS_REQUEST'
export const REPORTS_REQUEST_SUCCESS = 'REPORTS_REQUEST_SUCCESS'
export const REPORTS_REQUEST_FAIL = 'REPORTS_REQUEST_FAIL'

export const REPORT_DETAILS_BY_ID_REQUEST = 'REPORT_DETAILS_BY_ID_REQUEST'
export const REPORT_DETAILS_BY_HASH_REQUEST = 'REPORT_DETAILS_BY_HASH_REQUEST'
export const REPORT_DETAILS_BY_ID_SUCCESS = 'REPORT_DETAILS_BY_ID_SUCCESS'
export const REPORT_DETAILS_BY_ID_FAIL = 'REPORT_DETAILS_BY_ID_FAIL'

export const CHANGE_CURRENT_PAGE = 'CHANGE_CURRENT_PAGE'
export const CHANGE_LIMIT_PER_PAGE = 'CHANGE_LIMIT_PER_PAGE'
export const CHANGE_FILTER = 'CHANGE_FILTER'
export const CHANGE_TOTAL_ITEM = 'CHANGE_TOTAL_ITEM'
export const CHANGE_SEARCH_TEXT = 'CHANGE_SEARCH_TEXT'

export interface IReportsState {
  reportsList?: IReports[]
  reportsLoading: boolean
  reportsError: boolean

  reportId?: number
  reportData?: IReportDetails
  servicesRatingData?: IServiceRating

  currentPage?: number
  totalPages?: number
  perPage?: number
  filter?: string
  totalItem?: number
  searchText?: string
}

const initailState: IReportsState = {
  reportsList: [],
  reportsError: false,
  reportsLoading: false,

  reportId: undefined,
  reportData: undefined,
  servicesRatingData: undefined,

  currentPage: 1,
  totalPages: undefined,
  perPage: 20,
  filter: 'name',
  totalItem: 0,
  searchText: undefined,
}

export const reducer = (
  state: IReportsState = initailState,
  action: ActionWithPayload<IReportsState>
) => {
  switch (action.type) {
    //get user list
    case REPORTS_REQUEST:
      return {...state, reportsLoading: true}
    case REPORTS_REQUEST_SUCCESS:
      const reportsList = action.payload?.reportsList
      const currentPage = action.payload?.currentPage
      const totalPages = action.payload?.totalPages
      const perPage = action.payload?.perPage
      const totalItem = action.payload?.totalItem
      return {
        ...state,
        reportsList,
        currentPage,
        totalPages,
        perPage,
        totalItem,
        reportsLoading: false,
        reportsError: false,
      }
    case REPORTS_REQUEST_FAIL:
      return {...state, reportsLoading: false, reportsError: true}
    // get report by id
    case REPORT_DETAILS_BY_ID_REQUEST:
      return {
        ...state,
        reportId: action.payload?.reportId,
        reportData: undefined,
        servicesRatingData: undefined,
        reportsLoading: true,
      }
    case REPORT_DETAILS_BY_HASH_REQUEST:
      return {
        ...state,
        reportId: action.payload?.reportId,
        reportData: undefined,
        servicesRatingData: undefined,
        reportsLoading: true,
      }
    case REPORT_DETAILS_BY_ID_SUCCESS:
      const reportData = action.payload?.reportData
      const servicesRatingData = action.payload?.servicesRatingData
      return {
        ...state,
        reportData,
        servicesRatingData,
        reportsLoading: false,
      }
    case REPORT_DETAILS_BY_ID_FAIL:
      return {
        ...state,
        reportsLoading: false,
        reportsError: true,
      }
    // user list filters
    case CHANGE_CURRENT_PAGE:
      const changePage = action.payload?.currentPage
      return {...state, currentPage: changePage}
    case CHANGE_LIMIT_PER_PAGE:
      const changeLimit = action.payload?.perPage
      return {...state, perPage: changeLimit}
    case CHANGE_FILTER:
      const chnageFilter = action.payload?.filter
      return {...state, filter: chnageFilter}
    case CHANGE_TOTAL_ITEM:
      const changeTotal = action.payload?.totalItem
      return {...state, totalItem: changeTotal}
    case CHANGE_SEARCH_TEXT:
      const searchText = action.payload?.searchText
      return {...state, searchText}

    default:
      return state
  }
}

export const actions = {
  requestReports: () => ({type: REPORTS_REQUEST}),

  requestReportsSuccess: (
    reportsList: IReports[],
    currentPage: number,
    totalPages: number,
    perPage: number,
    totalItem: number
  ) => ({
    type: REPORTS_REQUEST_SUCCESS,
    payload: {reportsList, currentPage, totalPages, perPage, totalItem},
  }),

  requestReportsFail: () => ({type: REPORTS_REQUEST_FAIL}),

  requestReportById: (id: number, companyId?: number) => ({
    type: REPORT_DETAILS_BY_ID_REQUEST,
    payload: {reportId: id, companyId},
  }),
  requestReportByHash: (reportId: number, companyId: number, email: string, accessHash: string,) => ({
    type: REPORT_DETAILS_BY_HASH_REQUEST,
    payload: {reportId, companyId, email, accessHash},
  }),
  requestReportByIdSuccess: (reportData: IReportDetails, servicesRatingData?: IServiceRating) => ({
    type: REPORT_DETAILS_BY_ID_SUCCESS,
    payload: {reportData, servicesRatingData},
  }),
  requestReportByIdFail: () => ({
    type: REPORT_DETAILS_BY_ID_FAIL,
  }),

  changeCurrentPage: (newPage: number) => ({
    type: CHANGE_CURRENT_PAGE,
    payload: {currentPage: newPage},
  }),

  changeLimitPerPage: (newLimit: number) => ({
    type: CHANGE_LIMIT_PER_PAGE,
    payload: {perPage: newLimit},
  }),

  changeFilter: (newFilter: string) => ({
    type: CHANGE_FILTER,
    payload: {filter: newFilter},
  }),

  changeTotalItem: (newTotalItem: number) => ({
    type: CHANGE_TOTAL_ITEM,
    payload: {totalItem: newTotalItem},
  }),

  changeSearchText: (searchText: string) => ({
    type: CHANGE_SEARCH_TEXT,
    payload: {searchText},
  }),
}

export const selectors = {
  getReportsState: (state: RootState) => state.reports,
  getReportId: (state: RootState) => state.reports.reportId,
  getReportData: (state: RootState) => state.reports.reportData,
  getServicesRatingData: (state: RootState) => state.reports.servicesRatingData,
  getReportDetails: (state: RootState) => state.reports.reportData?.details,
  getCurrentPage: (state: RootState) => state.reports.currentPage,
  getLimitPerPage: (state: RootState) => state.reports.perPage,
  getFilter: (state: RootState) => state.reports.filter,
  getTotalItem: (state: RootState) => state.reports.totalItem,
  getSearchText: (state: RootState) => state.reports.searchText,
}

function* listUpdate() {
  const companyId: number = yield select(authSelectors.getCompanyId)
  const searchValue: string = yield select(selectors.getSearchText)
  const page: number = yield select(selectors.getCurrentPage)
  const limit: number = yield select(selectors.getLimitPerPage)
  const filter: string = yield select(selectors.getFilter)
  const {data} = yield getReportsList(companyId, page, limit, filter, searchValue)
  return {data}
}

interface ReportDetailsAction {
  type: string
  payload: {
    reportId: string
    companyId: string
    email?: string
    accessHash?: string
  }
}

function isReportDetails(
  data: unknown
): data is AxiosResponse<{report: IReportDetails; servicesRating?: IServiceRating; _status: string}> {
  return (
    (data as AxiosResponse<{report: IReportDetails; servicesRating?: IServiceRating; _status: string}>).data.report !== undefined
  )
}

export function* saga() {
  yield takeLatest(REPORTS_REQUEST, function* getReportsSaga() {
    try {
      const {data} = yield listUpdate()
      yield put(
        actions.requestReportsSuccess(
          data.items,
          data.currentPage,
          data.lastPage,
          data.perPage,
          data.total
        )
      )
    } catch (err) {
      yield put(actions.requestReportsFail())
    }
  })

  yield takeLatest(
    REPORT_DETAILS_BY_ID_REQUEST,
    function* getReportByIdSaga(action: ReportDetailsAction) {
      try {
        const data: unknown = yield getRaportById(
          Number(action.payload.reportId),
          Number(action.payload.companyId)
        );
        if (isReportDetails(data)) {
          const servicesRatingData: IServiceRating | undefined = yield getServiceRating(Number(action.payload.reportId), Number(action.payload.companyId));

          yield put(
            actions.requestReportByIdSuccess(
              data.data.report,
              servicesRatingData
            )
          );
        }
      } catch (err) {
        yield put(actions.requestReportByIdFail())
      }
    }
  )
  yield takeLatest(
    REPORT_DETAILS_BY_HASH_REQUEST,
    function* getReportByHashSaga(action: ReportDetailsAction) {
      try {
        const data: unknown = yield getReportByHash(
          Number(action.payload.reportId),
          Number(action.payload.companyId),
          String(action.payload.email),
          String(action.payload.accessHash)
        )
        if (isReportDetails(data)) {
          yield put(actions.requestReportByIdSuccess(data.data.report, data.data.servicesRating))
        }
      } catch (err) {
        yield put(actions.requestReportByIdFail())
      }
    }
  )
}
