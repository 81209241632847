import React, {useRef} from 'react'
import clsx from 'clsx'
import {IReportDetails, SEM_TAB_NAME} from '../../models/Reports'
import GAdsDataStatisticTabProps from './Tabs/GAdsDataStatisticTabProps'
import GadsStatisticTab from './Tabs/GadsStatisticTab'
import CommentTab from '../SeoStatistic/Tabs/CommentTab'
import {useSelector} from 'react-redux'
import {selectors} from '../../redux/ReportsRedux'
import AttachemntsTab from '../FbStatistic/Tabs/AttachmantsTab'

interface TabsContentProps {
  tab: string,
  isClicksZero?: boolean
}

const AdwTabsContent: React.FC<TabsContentProps> = ({tab, isClicksZero = false}) => {
  const reportData = useSelector(selectors.getReportData) as IReportDetails
  const componentRef = useRef<HTMLDivElement>(null)

  return (
    <>
      <div className='tab-content border'>
        <div className={clsx('tab-pane', {active: tab === SEM_TAB_NAME.COMMENT})}>
          <CommentTab />
        </div>
        <div className={clsx('tab-pane', {active: tab === SEM_TAB_NAME.DATA})}>
          {/*<ReactToPrint content={() => componentRef.current} trigger={reactToPrintTrigger} />*/}
          {/*<div className='report-to-pdf' ref={componentRef}>*/}
          {/*  /!*{tab === SEM_TAB_NAME.DATA && <DataStatisticTab />}*!/*/}
          {/*  <div className='d-none d-print-flex p-15 page-slice'>*/}
          {/*    <CommentTab />*/}
          {/*  </div>*/}
          {/*</div>*/}
        </div>
        {reportData && reportData.details && reportData.details.googleAdsConfig && !isClicksZero && (
          <div className={clsx('tab-pane', {active: tab === SEM_TAB_NAME.GADS})}>
            {/*<ReactToPrint content={() => componentRef.current} trigger={reactToPrintTrigger} />*/}
            {/*<div className='report-to-pdf' ref={componentRef}>*/}
            {tab === SEM_TAB_NAME.GADS && <GadsStatisticTab />}
            {/*<div className='d-none d-print-flex p-15 page-slice'>*/}
            {/*    <CommentTab />*/}
            {/*</div>*/}
            {/*</div>*/}
          </div>
        )}
        {reportData &&
          reportData.details &&
          reportData.details.GA4config &&
          reportData.details.GA4config.status && (
            <div className={clsx('tab-pane', {active: tab === SEM_TAB_NAME.GA4})}>
              {/*<ReactToPrint content={() => componentRef.current} trigger={reactToPrintTrigger} />*/}
              <div className='report-to-pdf' ref={componentRef}>
                {tab === SEM_TAB_NAME.GA4 && <GAdsDataStatisticTabProps />}
                <div className='d-none d-print-flex p-15 page-slice'>
                  <CommentTab />
                </div>
              </div>
            </div>
          )}

        {reportData && reportData.attachments && (
          <div className={clsx('tab-pane', {active: tab === SEM_TAB_NAME.ATTACHMENTS})}>
            {tab === SEM_TAB_NAME.ATTACHMENTS && <AttachemntsTab />}
          </div>
        )}
      </div>
    </>
  )
}

export default AdwTabsContent
