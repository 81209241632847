import {useEffect, useRef, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {convertToPlain} from '../../../../common/RichEditor/RichEditor'
import {useIntl} from "react-intl";

interface CommentWidgetProps {
  commentTitle: string
  commentBody?: string
}

const CommentWidget: React.FC<CommentWidgetProps> = ({commentTitle, commentBody}) => {
  const intl = useIntl()
  const descRef = useRef<HTMLDivElement | null>(null)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [commentText, setCommentText] = useState<string>('')

  useEffect(() => {
    if (commentBody) {
      if (commentBody.length > 500) {
        const sliceComment = commentBody.slice(0, 500) + '...'
        setCommentText(sliceComment)
      } else {
        setCommentText(commentBody)
      }
    }
  }, [commentBody])

  useEffect(() => {
    if (commentText) {
      if (descRef.current) {
        descRef.current.innerHTML = commentText
      }
    }
  }, [commentText])

  return (
    <>
      <div
        className='card comment-card h-100 border rounded-0 cursor-pointer'
        onClick={() => setShowModal(true)}
      >
        <div className='comment-card-header p-5 ps-10 border-bottom fw-boldest fs-4 text-sempai-green'>
          {commentTitle}
        </div>
        <div className='card-body d-print-none' ref={descRef}>
          {commentText && convertToPlain(commentText)}
        </div>
        {commentBody && (
          <div
            className='modal-body comment-tab d-none d-print-block'
            dangerouslySetInnerHTML={{__html: commentBody}}
          ></div>
        )}
        <button type="button" className='btn btn-green-simple'>{intl.formatMessage({id: 'CLICK_TO_READ_MORE'})}</button>
      </div>
      <Modal size='xl' show={showModal} onHide={() => setShowModal(false)}>
        <div className='modal-header d-flex justify-content-between align-items-center'>
          <h5 className='modal-title'>{commentTitle}</h5>
          <button type="button" className="btn-close" onClick={() => setShowModal(false)} aria-label="Close"></button>
        </div>
        <div className='modal-body comment-tab' dangerouslySetInnerHTML={{__html: commentBody || ''}}></div>
      </Modal>
    </>
  )
}

export default CommentWidget
