import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import {FB_TAB_NAME, IServiceRating} from '../models/Reports'
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { toAbsoluteUrl } from '../../../../_metronic/helpers';
import {UserModel} from '../../auth/models/UserModel'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../../setup'

const ratingImages: Record<number, string> = {
  1: toAbsoluteUrl('/media/sempai-images/reportsEmailRating/poor_more.png'),
  2: toAbsoluteUrl('/media/sempai-images/reportsEmailRating/poor.png'),
  3: toAbsoluteUrl('/media/sempai-images/reportsEmailRating/average.png'),
  4: toAbsoluteUrl('/media/sempai-images/reportsEmailRating/good.png'),
  5: toAbsoluteUrl('/media/sempai-images/reportsEmailRating/excellent.png'),
};

const SatisfactionBlock: React.FC<{ ratingData?: IServiceRating, showRating?: boolean }> = ({ ratingData, showRating = true }) => {
  const [currentRating, setCurrentRating] = useState<number | undefined>(undefined);
  const intl = useIntl();

  useEffect(() => {
    if (ratingData) {
      setCurrentRating(parseInt(ratingData.rating || '0', 10));
    }
  }, [ratingData]);

  if (!ratingData || !showRating) {
    return null;
  }

  const Alert = withReactContent(Swal);
  const { email, clientId, accessHash } = ratingData;

  const handleRating = (ratingValue: number) => {
    const encodedEmail = btoa(email);
    const baseUrl = (process.env.REACT_APP_API_URL || '').replace('/front/v1', '');
    const url = `${baseUrl}/feedback/${clientId}/${encodedEmail}/${ratingValue}/${accessHash}`;
    setTimeout(() => {
      window.open(url, '_blank');
    }, 1000);

    setCurrentRating(ratingValue);
    Alert.fire({
      icon: 'success',
      title: 'Sukces!',
      text: 'Operacja zakończona pomyślnie!',
      confirmButtonText: 'OK',
      timer: 3000,
      timerProgressBar: true,
      confirmButtonColor: '#5dae4d',
      allowEnterKey: true,
      allowOutsideClick: true,
    });
  };

  return (
    <div className='p-7 mb-5'>
      <b style={{fontSize: '1.25rem'}}>{intl.formatMessage({id: 'HOW_YOU_RATE_OUR_COO'})}</b>
      <table role="presentation" className={'p-5 my-4'} style={{width: '100%', textAlign: 'left'}}>
        <tbody>
        <tr>
          <td>
            {[1, 2, 3, 4, 5].map((ratingValue) => (
              <div className={'d-inline-block'}
                key={ratingValue}
                onClick={(e) => {
                  e.preventDefault();
                  handleRating(ratingValue);
                }}
                style={{
                  textDecoration: 'none',
                }}
              >
                <button
                  style={{
                    border: 'none',
                    background: 'transparent',
                    padding: '0 15px 0 0',
                    cursor: 'pointer',
                  }}
                >
                  <img
                    src={ratingImages[ratingValue]}
                    alt={`Rating ${ratingValue}`}
                    style={{
                      width: '45px',
                      verticalAlign: 'middle',
                      padding: '0',
                      filter: currentRating && currentRating !== ratingValue ? 'grayscale(70%)' : 'none',
                      opacity: currentRating && currentRating !== ratingValue ? '0.6' : '1',
                    }}
                  />
                </button>
              </div>
            ))}
          </td>
        </tr>
        </tbody>
      </table>
      <span style={{fontSize: '1.25rem'}}>{intl.formatMessage({id: 'RATE_DESCRIPTION'})}</span>
    </div>
  );
};

export default SatisfactionBlock;