import {useIntl} from 'react-intl'
import clsx from "clsx";
import React, {useState} from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import axios, {AxiosResponse} from "axios";
import {shallowEqual, useSelector} from "react-redux";
import {RootState} from "../../../setup";
import {IAuthState} from "../../modules/auth";
import {Switch, useHistory} from "react-router-dom";
import { Redirect } from 'react-router-dom';


const API_URL = process.env.REACT_APP_API_URL || 'api'

const loginSchema = Yup.object().shape({
    email: Yup.string().email('VALID.EMAIL_CORRECT').required('VALID.EMAIL_REQ'),
});

const initialValues = {
    email: '',
};
const ResetPassword: React.FC = () => {
    const history = useHistory();
    const intl = useIntl();
    const {loading, error} = useSelector<RootState>(({auth}) => auth, shallowEqual) as IAuthState;
    const [redirectToLogin, setRedirectToLogin] = useState(false);

    const formik = useFormik({
        initialValues,
        validationSchema: loginSchema,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            axios
                .post(`${API_URL}/password/reset`, { email: values.email })
                .then((response: AxiosResponse) => {
                    if (response.status === 200 || response.status === 201) {
                        console.log('Link do resetowania hasła został pomyślnie wysłany!');
                        setRedirectToLogin(true);
                    } else {
                        console.error(response.data.message);
                        setStatus(intl.formatMessage({ id: 'RESET.PASSWORD.ERROR.STATUS' }));
                    }
                })
                .catch((error) => {
                    console.error('Błąd podczas wysyłania linka resetującego hasło:', error);
                    setStatus(intl.formatMessage({ id: 'RESET.PASSWORD.ERROR.STATUS' }));
                })
                .finally(() => {
                    setSubmitting(false);
                });
        },
    });

    if (redirectToLogin) {
        return (
            <Switch>
                <Redirect to='/auth/login' />
            </Switch>
        )
    }

    return (
        <form
            className='form w-100'
            onSubmit={formik.handleSubmit}
            noValidate
            id='kt_login_signin_form'
        >
            <div className='text-center'>
                <h1 className='text-white mb-5 fw-bolder'>{intl.formatMessage({id: 'RESET.PASSWORD.TITLE'})}</h1>
            </div>

            {formik.status && (
                <div className='mb-lg-15 alert alert-danger'>
                    <div className='alert-text font-weight-bold'>{formik.status}</div>
                </div>
            )}

            <div className='fv-row mb-10'>
                <label className='form-label fs-6 fw-bolder text-white'>
                    {intl.formatMessage({id: 'EMAIL'})}
                </label>
                <input
                    placeholder={intl.formatMessage({id: 'EMAIL'})}
                    {...formik.getFieldProps('email')}
                    className={clsx(
                        'form-control form-control-lg form-control-solid',
                        {'is-invalid': formik.touched.email && formik.errors.email},
                        {
                            'is-valid': formik.touched.email && !formik.errors.email,
                        }
                    )}
                    type='email'
                    name='email'
                    autoComplete='off'
                />
                {formik.touched.email && formik.errors.email && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                            <span role='alert'>{intl.formatMessage({id: formik.errors.email})}</span>
                        </div>
                    </div>
                )}
            </div>

            <div className='text-center'>
                <button
                    type='submit'
                    id='kt_sign_in_submit'
                    className='btn btn-lg btn-yellow w-100'
                    disabled={formik.isSubmitting || !formik.isValid}
                >
                    {!loading && (
                        <span className='indicator-label'>{intl.formatMessage({id: 'USER.EDIT.PASS_RESET'})}</span>
                    )}
                    {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                            {intl.formatMessage({id: 'USER.WAIT'})}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                    )}
                </button>
            </div>
        </form>
    );
};

export default ResetPassword